import { PaymentFailedShort } from 'apps/Offer/screens/PaymentFailedShort';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { ACH_PAYMENT_INFO, getParsedJsonCookie } from 'shared/util';

const appendRedirectUrl = (redirectPath, searchParams) => {
  searchParams.append('redirect_url', window.location.href);
  return redirectPath + '?' + searchParams.toString();
};

const getRedirect = achPaymentInfo => {
  const { type, ...achData } = achPaymentInfo;
  const searchParams = new URLSearchParams();

  if (type === 'invoice') {
    return appendRedirectUrl(
      `/invoices/${achData.invoiceUid}/ach-oauth-process`,
      searchParams
    );
  } else {
    searchParams.append('contactPhoneNumber', achData.contactPhoneNumber);
    searchParams.append('contactName', achData.contactName);
    searchParams.append('locationUid', achData.locationUid);
    return appendRedirectUrl(
      `/offers/${achData.offerUid}/ach-oauth-process`,
      searchParams
    );
  }
};

export const PaymentAchRedirectLanding = () => {
  const achPaymentInfo = getParsedJsonCookie(ACH_PAYMENT_INFO);

  if (!achPaymentInfo) {
    return <PaymentFailedShort />;
  }

  const redirect = getRedirect(achPaymentInfo);

  return redirect ? <Redirect to={redirect} /> : <PaymentFailedShort />;
};
