const ESTIMATES_TRACKING_EVENTS = {
  consumerView: {
    opened: true,
  },
  approveButton: {
    clicked: true,
  },
};

export default ESTIMATES_TRACKING_EVENTS;
