import React from 'react';
import { colors } from '@podiumhq/podium-ui';

function Visa(props) {
  return (
    <svg
      width={props.size || '24px'}
      height={props.size || '24px'}
      viewBox="0 0 24 24"
      version="1.1"
      style={{ borderRadius: 3 }}
    >
      <title>Group 13 Copy</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Exploration"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Discover-options-Copy"
          transform="translate(-29.000000, -65.000000)"
        >
          <g id="Group-13-Copy" transform="translate(29.000000, 65.000000)">
            <g id="Group-11">
              <g id="Group-17">
                <rect
                  id="Rectangle"
                  fill="#3957A7"
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                />
                <g id="Group-18" transform="translate(2.250000, 9.000000)">
                  <polyline
                    id="Fill-343"
                    fill={`${colors.white}`}
                    points="8.35190132 5.7492 6.77984211 5.7492 7.76253947 0.10104 9.33459868 0.10104 8.35190132 5.7492"
                  />
                  <path
                    d="M5.45717763,0.10104 L3.9585,3.98592 L3.78094737,3.14928 L3.78094737,3.14952 L3.25239474,0.6096 C3.25239474,0.6096 3.18825,0.10104 2.50651974,0.10104 L0.0284802632,0.10104 L7.95807864e-13,0.1968 C7.95807864e-13,0.1968 0.757677632,0.34392 1.64415789,0.84216 L3.00967105,5.7492 L4.64792763,5.7492 L7.14957237,0.10104 L5.45717763,0.10104"
                    id="Fill-344"
                    fill={`${colors.white}`}
                  />
                  <path
                    d="M16.0934013,3.75144 L16.9190724,1.63728 L17.38425,3.75144 L16.0934013,3.75144 Z M17.8232566,5.7492 L19.2665132,5.7492 L18.0079934,0.10104 L16.7443421,0.10104 C16.1603684,0.10104 16.0182237,0.52176 16.0182237,0.52176 L13.6738618,5.7492 L15.3126316,5.7492 L15.6397697,4.9104 L17.6387763,4.9104 L17.8232566,5.7492 L17.8232566,5.7492 Z"
                    id="Fill-345"
                    fill={`${colors.white}`}
                  />
                  <path
                    d="M13.7965066,1.4592 L14.0205,0.24624 C14.0205,0.24624 13.3285066,3.4283687e-13 12.6070066,3.4283687e-13 C11.82675,3.4283687e-13 9.97399342,0.31896 9.97399342,1.86984 C9.97399342,3.32928 12.1490132,3.34728 12.1490132,4.11336 C12.1490132,4.87968 10.1979868,4.74288 9.55448684,4.25952 L9.32048684,5.5272 C9.32048684,5.5272 10.0224868,5.84664 11.0949868,5.84664 C12.168,5.84664 13.7865,5.3268 13.7865,3.91296 C13.7865,2.4444 11.59275,2.30736 11.59275,1.66896 C11.59275,1.03032 13.1240132,1.1124 13.7965066,1.4592"
                    id="Fill-346"
                    fill={`${colors.white}`}
                  />
                  <path
                    d="M3.78094737,3.14952 L3.25239474,0.6096 C3.25239474,0.6096 3.18825,0.10104 2.50651974,0.10104 L0.0284802632,0.10104 L2.16182627e-12,0.1968 C2.16182627e-12,0.1968 1.19078289,0.42744 2.33281579,1.29216 C3.42481579,2.1192 3.78094737,3.14952 3.78094737,3.14952"
                    id="Fill-347"
                    fill="#F9A533"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Visa;
