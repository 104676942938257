import { datadogRum } from '@datadog/browser-rum';
import env from 'env';

export default function initDatadog(initOpts = {}) {
  if (process.env.REACT_APP_DATADOG_CLIENT_TOKEN) {
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'niffler',
      version: env.REACT_APP_VERSION,
      env: process.env.REACT_APP_ENV,
      sampleRate: 100,
      premiumSampleRate: 0,
      trackInteractions: false,
      defaultPrivacyLevel: 'mask-user-input',
      ...initOpts,
    });
  }
}
