import { getCookie } from './util';

export const CUSTOMER_UID_COOKIE = 'customerUid';
export const VERIFICATION_CODE_COOKIE = 'verificationCode';
export const AUTHENTICATED = 'AUTHENTICATED';
export const EXPIRED = 'expired';
export const INVALID_CODE = 'invalid_code';
export const TOO_MANY_ATTEMPTS = 'too_many_attempts';

export const getTextCodeAuthenticationHeader = (
  verificationCode,
  customerUid
) => ({
  'Text-Code-Authentication': JSON.stringify({
    customer_uid: customerUid,
    text_code: verificationCode,
  }),
});

export const getHasVerificationCodeCookie = () =>
  !!getCookie(VERIFICATION_CODE_COOKIE);

export const getHasVerificationCookies = () =>
  !!(getHasVerificationCodeCookie() && getCookie(CUSTOMER_UID_COOKIE));

/**
 * This function is needed for the cases when after the payment is completed,
 * we need to update recurring invoice, which is text auth protected functionality
 *
 * Corner case scenario example:
 * 1. User opens an invoice with recurring functionality
 * 2. User loads saved cards with text auth.
 * 3. User enables recurring invoice.
 * 4. User clicks Pay button after some time and verification cookie expires by that time.
 * 5. Verification pops up, payment is completed upon completing verification
 *  - Without verification in this scenario the payment will be completed,
 *    but the card which was selected for the payment will
 *    not be saved for recurring invoice, because saving recurring invoice mutation requires
 *    verification code and happens after the payment is completed.
 */
export const submitPaymentFormAndMaybeVerifyCode = ({
  event,
  invoice,
  submitForm,
  configureVerificationFlow,
}) => {
  event.preventDefault();

  const needsVerification =
    invoice?.recurringInvoice?.autoDebit ||
    invoice?.recurringInvoice?.isSubscription;

  if (configureVerificationFlow && needsVerification) {
    configureVerificationFlow({
      verificationSuccessAction: submitForm,
    });
  } else {
    submitForm();
  }
};
