/**
 * Various ways the app can be launched/entered
 */
export const ENTRY_POINTS = {
  CARD_READER_PAGE: 'cardReaderPage',
  CARD_READER_SETUP: 'cardReaderSetup',
  CONTACT_PROFILE: 'contactProfile',
  CONTACT_TABLE: 'contactTable',
  ESTIMATES_TABLE: 'estimatesTable',
  ESTIMATE_DETAILS: 'estimateDetails',
  INBOX: 'inbox',
  MEGA_BUTTON: 'megaButton',
  MODAL: 'modal',
  PAYMENT_ACTIVITY: 'paymentActivity',
  PAYMENTS_ACTION_BANNER: 'paymentActionBanner',
  PAYMENTS_HOME: 'paymentsHome',
  PAYMENTS_SETUP_MODAL: 'paymentsSetupModal',
};
