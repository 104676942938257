const UNIVERSAL_PAYMENTS_TRACKING_EVENTS = {
  suggestions: {
    invoiceSent: true,
  },
  universalRequestModal: {
    invoiceSent: true,
  },
  universalPayments: {
    addedLineItem: true,
    addedTemplateVariable: true,
    changedBankAccount: true,
    chargeFailed: true,
    chargeSucceeded: true,
    clickedAcceptedPayments: true,
    clickedAccountUpsell: true,
    clickedAmountInput: true,
    clickedAttachRequest: true,
    clickedCancelCharge: true,
    clickedClearContact: true,
    clickedDueDate: true,
    clickedEditContact: true,
    clickedEmojiDock: true,
    clickedExistingContact: true,
    clickedHelpCenter: true,
    clickedNewContact: true,
    clickedPaymentSchedule: true,
    clickedPodiumLogo: true,
    clickedPrintReceipt: true,
    clickedReaderUpsell: true,
    clickedRequestOptions: true,
    clickedSendAnotherPayment: true,
    clickedSendRequest: true,
    clickedSendReviewInvite: true,
    clickedStartCharge: true,
    clickedSupportChat: true,
    clickedSupportEmail: true,
    clickedSupportMenu: true,
    clickedTroubleshootingTips: true,
    contactInputClicked: true,
    deletedLineItem: true,
    enteredInvoiceNumber: true,
    enteredLineItemDescription: true,
    launchedFullScreen: true,
    openedAccountSelection: true,
    openedLocationSelection: true,
    openedReaderSelection: true,
    openedUniversalPayments: true,
    progressedUPStage: true,
    requestFailed: true,
    requestSucceeded: true,
    reviewInviteFailed: true,
    reviewInviteSucceeded: true,
    selectedEmoji: true,
    serviceChargeCleared: true,
    setAcceptedPayments: true,
    switchedDueDate: true,
    switchedLocation: true,
    switchedPaymentMethod: true,
    switchedPaymentSchedule: true,
    switchedPaymentScheduleFrequency: true,
    switchedSelectedReader: true,
    toggledAcceptedPayments: true,
  },
  manualEntry: {
    checkedShouldSaveCardOnFile: true,
    uncheckedShouldSaveCardOnFile: true,
    onChargeSaveCardOnFile: true,
    onChargeDoNotSaveCardOnFile: true,
  },
  cardPresent: {
    checkedShouldSaveCardOnFile: true,
    uncheckedShouldSaveCardOnFile: true,
    onChargeSaveCardOnFile: true,
    onChargeDoNotSaveCardOnFile: true,
  },
};

export default UNIVERSAL_PAYMENTS_TRACKING_EVENTS;
