import {
  CenterColumn,
  CircledIcon,
  InvoiceContainer,
  LayoutWrapper,
  PaymentMessage,
  Section,
  StatusImageFailed,
} from 'apps/Invoice/components/InvoiceStyledComponents';
import { useState } from 'react';
import SecurePayment from 'shared/components/SecurePayments/SecurePayment';
import PoweredByPodium from 'shared/components/PoweredByPodium';

export const PaymentFailedShort = () => {
  const [showSecurePayment, setShowSecurePayment] = useState(false);

  function handleLearnMore() {
    setShowSecurePayment(show => !show);
  }

  if (showSecurePayment) {
    return <SecurePayment iconX={handleLearnMore} />;
  }

  return (
    <>
      <LayoutWrapper>
        <InvoiceContainer>
          <CenterColumn>
            <Section>
              <CircledIcon>
                <StatusImageFailed />
              </CircledIcon>
            </Section>
            <PaymentMessage id="selenium-failed-payment-message" bold large>
              Sorry, we're unable to process your payment.
            </PaymentMessage>
            <Section>
              <PaymentMessage>Please, try again.</PaymentMessage>
            </Section>
          </CenterColumn>
          <PoweredByPodium handleLearnMore={handleLearnMore} />
        </InvoiceContainer>
      </LayoutWrapper>
    </>
  );
};
