import React from 'react';
import { Box, Icon, Flex, Link } from '@podiumhq/design-system';

const PoweredByPodium = ({
  handleLearnMore,
  textingCopy,
  textingLink,
  ...props
}) => {
  const onClick = () => {
    if (handleLearnMore) {
      handleLearnMore();
    }
  };

  return (
    <Flex justifyContent="center" mt="43px" {...props}>
      {textingCopy && textingLink && (
        <Link
          color="blue.base"
          fontSize="sm"
          fontWeight="semibold"
          href={textingLink}
          isExternal
          mr={2}
        >
          Try texting your estimates
        </Link>
      )}
      <Box
        fontSize="sm"
        fontWeight="semibold"
        id="selenium-secure-payments-link"
        onClick={onClick}
      >
        {textingCopy && textingLink ? 'powered by' : 'Powered by'}
        <Icon boxSize="17px" color="black.base" ml="2px" mr="2px" type="Logo" />
        Podium
      </Box>
    </Flex>
  );
};
export default PoweredByPodium;
