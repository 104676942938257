const ONBOARDING_TRACKING_EVENTS = {
  onboardingGetStarted: {
    nextClicked: true,
    closeClicked: true,
  },
  onboardingAccountCreator: {
    nextClicked: true,
    closeClicked: true,
  },
  onboardingAccountOwner: {
    nextClicked: true,
    closeClicked: true,
  },
  onboardingBusinessDetails: {
    nextClicked: true,
    closeClicked: true,
  },
  onboardingBankAccount: {
    nextClicked: true,
    closeClicked: true,
  },
  onboardingSummary: {
    nextClicked: true,
    closeClicked: true,
  },
  onboardingCustomizeInvoice: {
    nextClicked: true,
    closeClicked: true,
  },
  onboardingConfirmPaymentMethods: {
    nextClicked: true,
    closeClicked: true,
    affirmToggledOff: true,
  },
  onboardingIdVerification: {
    submitted: true,
    nextClicked: true,
    startClicked: true,
    cancelIdverificationClicked: true,
  },
};

export default ONBOARDING_TRACKING_EVENTS;
