import React from 'react';
import styled from 'styled-components/macro';
import { colors, IconLock, IconX } from '@podiumhq/podium-ui';
import Amex from 'shared/components/Card/AmEx';
import Discover from 'shared/components/Card/Discover';
import Mastercard from 'shared/components/Card/Mastercard';
import Visa from 'shared/components/Card/Visa';

import Lock from './SecurityIcons/Lock';
import Shield from './SecurityIcons/Shield';
import Badge from './SecurityIcons/Badge';
import PoweredByPodium from 'shared/components/PoweredByPodium';

const SecurePageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.white};
`;

const IconXBase = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;

  svg:hover {
    cursor: pointer;
  }

  svg {
    fill: currentColor;
  }
`;

const SecureBadgesWrapper = styled.div`
  width: 189px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 9px;
  margin-bottom: 55px;
`;

const SecureSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 90px;
  text-align: center;
  max-width: 270px;
`;

const LockIcon = styled(IconLock)`
  fill: ${colors.white};
`;

const PaymentMessage = styled.div`
  color: ${colors.mineShaft};
  font-size: ${props => (props.bold ? `18px` : `16px`)};
  font-weight: ${props => (props.bold ? 600 : `normal`)};
  letter-spacing: 0px;
  text-align: center;
  width: ${props => props.width || 291}px;
`;

const IconXWrapper = ({ id, onClick }) => (
  <IconXBase id={id}>
    <IconX color={colors.lightSteel} size="large" onClick={onClick} />
  </IconXBase>
);

const GuaranteeMessage = styled.div`
  color: ${colors.mineShaft};
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
`;

const CardsWrapper = styled.div`
  box-sizing: content-box;
  width: 162px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 22px;
`;

const CircledIcon = styled.div`
  align-items: center;
  height: 48px;
  width: 48px;
  background: ${props => props.backgroundColor || colors.whiteSmoke};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin-bottom: ${props => props.marginBottom || '18px'};
  margin-top: ${props => props.marginTop || '18px'};
  margin-left: 18px;
  margin-right: 18px;
`;

const SecurePayment = props => {
  const cardSize = '34px';

  return (
    <SecurePageWrapper>
      <IconXWrapper id="selenium-close-secure-payment" onClick={props.iconX} />
      <SecureSection>
        <CircledIcon backgroundColor={colors.cobaltBlue}>
          <LockIcon size="large" />
        </CircledIcon>
        <GuaranteeMessage>Secure Payments Guaranteed.</GuaranteeMessage>
        <p style={{ color: colors.mineShaft }}>
          All transactions are PCI compliant and are processed with SSL
          encryption to ensure your credit card information is safe.
        </p>
        <SecureBadgesWrapper>
          <Shield />
          <Lock />
          <Badge />
        </SecureBadgesWrapper>
        <PaymentMessage bold>We accept all major credit cards.</PaymentMessage>
        <CardsWrapper>
          <Visa size={cardSize} />
          <Amex size={cardSize} />
          <Mastercard size={cardSize} />
          <Discover size={cardSize} />
        </CardsWrapper>
      </SecureSection>
      <PoweredByPodium mt="0" />
    </SecurePageWrapper>
  );
};

export default SecurePayment;
