const PAYMENT_ACTIVITY_TRACKING_EVENTS = {
  paymentActivity: {
    emptyStatePaymentRequestClicked: true,
    disputeTabClicked: true,
  },
  paymentsHome: {
    disputeVisited: true,
  },
};

export default PAYMENT_ACTIVITY_TRACKING_EVENTS;
