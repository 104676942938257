import React from 'react';
import { colors } from '@podiumhq/podium-ui';

function Discover(props) {
  return (
    <svg
      width={props.size || '24px'}
      height={props.size || '24px'}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      style={{ borderRadius: 3 }}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <rect id="path-1" width="24" height="24" />
        <path
          d="M7,9.93494804 C7,12.8340561 9.14462843,15 12.0059995,15 C14.7969762,15 17,12.8054169 17,9.99140823 C17,7.19621962 14.8113751,5 12.0059995,5 C9.24142069,5 7,7.20849358 7,9.93494804 Z"
          id="path-3"
        />
        <filter
          x="-15%"
          y="-15%"
          width="130%"
          height="130%"
          filterUnits="objectBoundingBox"
          id="filter-4"
        >
          <feGaussianBlur
            stdDeviation="1"
            in="SourceAlpha"
            result="shadowBlurInner1"
          />
          <feOffset
            dx="1"
            dy="1"
            in="shadowBlurInner1"
            result="shadowOffsetInner1"
          />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            operator="arithmetic"
            k2="-1"
            k3="1"
            result="shadowInnerInner1"
          />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
            in="shadowInnerInner1"
          />
        </filter>
      </defs>
      <g id="Exploration" fill="none" fillRule="evenodd">
        <g id="Discover-options-Copy" transform="translate(-29 -242)">
          <g id="Group-21" transform="translate(29 242)">
            <g id="Group-19">
              <g id="Group-13-Copy">
                <mask id="mask-2" fill={`${colors.white}`}>
                  <use xlinkHref="#path-1" />
                </mask>
                <use
                  id="Rectangle"
                  fill={`${colors.whiteSmoke}`}
                  xlinkHref="#path-1"
                />
                <path
                  d="M24,14.5625 L24,19.0586114 C24,21.5460404 21.9917012,23.5625 19.5010013,23.5625 L-2.14841268e-14,23.5625 C10.2047244,21.4103261 17.5748031,18.4755435 24,14.5625 Z"
                  id="shape"
                  fill="#F58220"
                  mask="url(#mask-2)"
                />
              </g>
              <g id="Fill-1-Copy">
                <use fill="#F48027" xlinkHref="#path-3" />
                <use fill="#000" filter="url(#filter-4)" xlinkHref="#path-3" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Discover;
