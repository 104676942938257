import { useSmartSnackbar, SmartSnackbar } from './SmartSnackbar';

export let showInfoMessage = () => {};
export let showErrorMessage = () => {};

export const GlobalSmartSnackbar = () => {
  const {
    external: smartSnackbarLogicExternal,
    internal: smartSnackbarLogicInternal,
  } = useSmartSnackbar();

  showInfoMessage = smartSnackbarLogicExternal.showInfoMessage;
  showErrorMessage = smartSnackbarLogicExternal.showErrorMessage;

  return (
    <SmartSnackbar isCenteredInPortal={true} {...smartSnackbarLogicInternal} />
  );
};
