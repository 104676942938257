import React from 'react';

function Mastercard(props) {
  return (
    <svg
      width={props.size || '24px'}
      height={props.size || '24px'}
      viewBox="0 0 24 24"
      version="1.1"
      style={{ borderRadius: 3 }}
    >
      <title>Group 16</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Exploration"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Discover-options-Copy"
          transform="translate(-29.000000, -27.000000)"
        >
          <g id="Group-16" transform="translate(29.000000, 27.000000)">
            <g id="Group-13" fill="#353A48">
              <rect id="Rectangle" x="0" y="0" width="24" height="24" />
            </g>
            <g id="mastercard-logo" transform="translate(3.000000, 7.000000)">
              <path
                d="M9,1.39387622 C9.95325768,0.574587633 11.1897925,0.08 12.5409836,0.08 C15.5559151,0.08 18,2.54243388 18,5.58 C18,8.61756612 15.5559151,11.08 12.5409836,11.08 C11.1897925,11.08 9.95325768,10.5854124 9,9.76612378 C8.04674232,10.5854124 6.81020753,11.08 5.45901639,11.08 C2.44408489,11.08 0,8.61756612 0,5.58 C0,2.54243388 2.44408489,0.08 5.45901639,0.08 C6.81020753,0.08 8.04674232,0.574587633 9,1.39387622 Z"
                id="Combined-Shape"
                fill="#FF5E00"
              />
              <path
                d="M9,1.39387622 C7.82624061,2.40267761 7.08196721,3.90376909 7.08196721,5.58 C7.08196721,7.25623091 7.82624061,8.75732239 9,9.76612378 C8.04674232,10.5854124 6.81020753,11.08 5.45901639,11.08 C2.44408489,11.08 0,8.61756612 0,5.58 C0,2.54243388 2.44408489,0.08 5.45901639,0.08 C6.81020753,0.08 8.04674232,0.574587633 9,1.39387622 Z"
                id="Combined-Shape"
                fill="#ED0006"
              />
              <path
                d="M9,9.76612378 C10.1737594,8.75732239 10.9180328,7.25623091 10.9180328,5.58 C10.9180328,3.90376909 10.1737594,2.40267761 9,1.39387622 C9.95325768,0.574587633 11.1897925,0.08 12.5409836,0.08 C15.5559151,0.08 18,2.54243388 18,5.58 C18,8.61756612 15.5559151,11.08 12.5409836,11.08 C11.1897925,11.08 9.95325768,10.5854124 9,9.76612378 Z"
                id="Combined-Shape"
                fill="#F9A000"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Mastercard;
