import { Socket } from 'phoenix';

import moment from 'moment-timezone';

function getLogger(name) {
  return (kind, msg, data) => {
    const date = moment().format('HH:mm:ss');
    console.debug(`🔌 [${date}] ${name} — ${kind}: ${msg}`, data);
  };
}

export default class PhoenixSocket extends Socket {
  static async create(endpoint, endpointName, opts) {
    return new PhoenixSocket(endpoint, endpointName, opts);
  }

  constructor(endpoint, endpointName, opts) {
    super(endpoint, {
      ...opts,
      logger: getLogger(endpointName),
      params: () => ({
        ...opts.params,
      }),
    });

    this._lastMessage = new Date();

    this._onReconnectCallbacks = new Set();

    this.onClose(() => {
      this.wasClosed = true;
      this.reconnectTimer.scheduleTimeout();
    });

    this.onMessage(() => {
      this._lastMessage = new Date();
    });

    this.onOpen(() => {
      if (this.wasClosed) {
        // Clone the set so that we can modify it during the forEach without issues
        new Set(this._onReconnectCallbacks).forEach(cbObj => {
          const { cb, delay } = cbObj;
          if (!delay || (new Date() - this._lastMessage) / 1000 > delay) {
            try {
              cb();
            } catch (e) {
              // Sometimes `refetch` is undefined because we've unmounted
              this._onReconnectCallbacks.delete(cbObj);
            }
          }
        });
      }

      this.wasClosed = false;
    });
  }

  onReconnect = (cb, delay) => {
    const cbObj = { cb, delay: delay || 0 };
    this._onReconnectCallbacks.add(cbObj);
    return () => this._onReconnectCallbacks.delete(cbObj);
  };
}
