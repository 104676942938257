import React from 'react';
import { colors } from '@podiumhq/podium-ui';

function Lock() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="74"
      height="24"
      viewBox="0 0 74 24"
    >
      <defs>
        <path
          id="a"
          d="M3.512235 7.41334135L0.548918269 4.61538462 1.85216795 3.38709928 3.46704166 4.88322636 8.25858688 0 9.49987981 1.3421875z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <text
          fill={`${colors.iron}`}
          fontFamily="Graphik-Medium, Graphik"
          fontSize="14"
          fontWeight="400"
          letterSpacing="-0.2"
          transform="translate(-157 -441) translate(-.5 94) translate(157.5 347)"
        >
          <tspan x="21" y="15">
            SECURE
          </tspan>
        </text>
        <text
          fill={`${colors.iron}`}
          fontFamily="Graphik-Regular, Graphik"
          fontSize="12"
          fontWeight="normal"
          transform="translate(-157 -441) translate(-.5 94) translate(157.5 347)"
        >
          <tspan
            x="21"
            y="23"
            fontFamily="Graphik-Medium, Graphik"
            fontSize="8"
            fontWeight="400"
          >
            PAYMENT
          </tspan>
        </text>
        <path
          fill={`${colors.iron}`}
          d="M12.79 10.681H5.67V5.934a3.565 3.565 0 013.56-3.56 3.565 3.565 0 013.56 3.56v4.747zm2.374 0V5.934A5.94 5.94 0 009.23 0a5.94 5.94 0 00-5.934 5.934v4.747a2.375 2.375 0 00-2.374 2.374v8.308a2.376 2.376 0 002.374 2.373h11.868a2.376 2.376 0 002.374-2.373v-8.308a2.375 2.375 0 00-2.374-2.374z"
          transform="translate(-157 -441) translate(-.5 94) translate(157.5 347)"
        />
        <g transform="translate(-157 -441) translate(-.5 94) translate(157.5 347) translate(4.615 12.923)">
          <mask fill={`${colors.white}`}>
            <use xlinkHref="#a" />
          </mask>
          <use fill={`${colors.white}`} xlinkHref="#a" />
        </g>
      </g>
    </svg>
  );
}

export default Lock;
