const RECEIPT_TRACKING_EVENTS = {
  receiptToggleSavedCards: {
    checked: true,
    unChecked: true,
  },
  receipt: {
    viewed: true,
    manageSavedCardsClicked: true,
  },
  receiptScreen: {
    errorOccured: true,
  },
  printReceipt: {
    buttonClicked: true,
  },
} as const;

export default RECEIPT_TRACKING_EVENTS;
