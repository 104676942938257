import { MOCKED_COOKIE } from 'shared/cookies';
import { getCookie } from './util';

export default function isMocked() {
  try {
    return !!getCookie(MOCKED_COOKIE);
  } catch (error) {
    // failed to get the cookie, return false
    return false;
  }
}
