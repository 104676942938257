const INVOICE_TRACKING_EVENTS = {
  invoiceDetails: {
    opened: true,
  },
  creditCard: {
    futureUsageChecked: true,
    futureUsageUnchecked: true,
    paid: true,
    paidAndSetupAutoPay: true,
    paidAndSavedCard: true,
    paidAndHealedSubscription: true,
  },
  textAuth: {
    correctCodeEntered: true,
    incorrectCodeEntered: true,
    opened: true,
    resendCodeClicked: true,
    verifyClicked: true,
    otpFlowCanceled: true,
  },
  cardEntryForm: {
    pageViewed: true,
    cardOnFileChecked: true,
    cardOnFileUnchecked: true,
    payClicked: true,
  },
  success: {
    pageViewed: true,
    manageSavedCardsClicked: true,
  },
  pending: {
    pageViewed: true,
  },
  manageSavedCards: {
    modalViewed: true,
    closeClicked: true,
    deleteCardClicked: true,
    cardOnFileChecked: true,
    cardOnFileUnchecked: true,
  },
  removeThisCard: {
    modalViewed: true,
    removeCardClicked: true,
    closeClicked: true,
    cancelClicked: true,
  },
  paymentMethodsPage: {
    pageViewed: true,
    checkoutWithAffirmClicked: true,
  },
  freeTrialLink: {
    clicked: true,
  },
};

export default INVOICE_TRACKING_EVENTS;
