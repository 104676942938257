window.env = window.env || {};

const getEnv = key => {
  return window.env[key] || process.env[key];
};

const env = {
  REACT_APP_ACCOUNTS_URL: getEnv('REACT_APP_ACCOUNTS_URL'),
  REACT_APP_AMPLITUDE_TOKEN: getEnv('REACT_APP_AMPLITUDE_TOKEN'),
  REACT_APP_AMPLITUDE_EXPERIMENT_TOKEN: getEnv(
    'REACT_APP_AMPLITUDE_EXPERIMENT_TOKEN'
  ),

  REACT_APP_COHERE_API_KEY: getEnv('REACT_APP_COHERE_API_KEY'),
  REACT_APP_FULLSTORY_ORG_ID: getEnv('REACT_APP_FULLSTORY_ORG_ID'),
  REACT_APP_GOOGLE_MAPS_API_KEY: getEnv('REACT_APP_GOOGLE_MAPS_API_KEY'),
  REACT_APP_GRINGOTTS_PUBLIC_WEBSOCKET_URI: getEnv(
    'REACT_APP_GRINGOTTS_PUBLIC_SCHEMA_WEBSOCKET_URI'
  ),
  REACT_APP_GRINGOTTS_WEBSOCKET_URI: getEnv(
    'REACT_APP_GRINGOTTS_SCHEMA_WEBSOCKET_URI'
  ),
  REACT_APP_GRINGOTTS_URI: getEnv('REACT_APP_GRINGOTTS_URI'),
  REACT_APP_KAZAAM_URL: getEnv('REACT_APP_KAZAAM_URL'),
  REACT_APP_MESSENGER_URL: getEnv('REACT_APP_MESSENGER_URL'),
  REACT_APP_NIFFLER_URL: getEnv('REACT_APP_NIFFLER_URL'),
  REACT_APP_ONBOARDING_COMPLETED_REDIRECT_URL: getEnv(
    'REACT_APP_ONBOARDING_COMPLETED_REDIRECT_URL'
  ),
  REACT_APP_PENDO_API_KEY: getEnv('REACT_APP_PENDO_API_KEY'),
  REACT_APP_PLAID_ENV: getEnv('REACT_APP_PLAID_ENV'),
  REACT_APP_PLAID_PUBLIC_KEY: getEnv('REACT_APP_PLAID_PUBLIC_KEY'),
  REACT_APP_PLAID_SANDBOX_ENV: getEnv('REACT_APP_PLAID_SANDBOX_ENV'),
  REACT_APP_SALESFORCE_BASE_LIVE_AGENT_URL: getEnv(
    'REACT_APP_SALESFORCE_BASE_LIVE_AGENT_URL'
  ),
  REACT_APP_SALESFORCE_BASE_URL: getEnv('REACT_APP_SALESFORCE_BASE_URL'),
  REACT_APP_SALESFORCE_BUTTON_ID_AU: getEnv(
    'REACT_APP_SALESFORCE_BUTTON_ID_AU'
  ),
  REACT_APP_SALESFORCE_BUTTON_ID_US: getEnv(
    'REACT_APP_SALESFORCE_BUTTON_ID_US'
  ),
  REACT_APP_SALESFORCE_DEPLOYMENT_ID: getEnv(
    'REACT_APP_SALESFORCE_DEPLOYMENT_ID'
  ),
  REACT_APP_SALESFORCE_INTERNAL_DOMAIN_URL: getEnv(
    'REACT_APP_SALESFORCE_INTERNAL_DOMAIN_URL'
  ),
  REACT_APP_SALESFORCE_ORGANIZATION_ID: getEnv(
    'REACT_APP_SALESFORCE_ORGANIZATION_ID'
  ),
  REACT_APP_SALESFORCE_PUBLIC_DOMAIN_URL: getEnv(
    'REACT_APP_SALESFORCE_PUBLIC_DOMAIN_URL'
  ),
  REACT_APP_SALESFORCE_BASE_LIVE_AGENT_CONTENT_URL: getEnv(
    'REACT_APP_SALESFORCE_BASE_LIVE_AGENT_CONTENT_URL'
  ),
  REACT_APP_SALESFORCE_SCRIPT_URL: getEnv('REACT_APP_SALESFORCE_SCRIPT_URL'),
  REACT_APP_STRIPE_PUBLIC_KEY: getEnv('REACT_APP_STRIPE_PUBLIC_KEY'),
  REACT_APP_STRIPE_TEST_PUBLIC_KEY: getEnv('REACT_APP_STRIPE_TEST_PUBLIC_KEY'),
  REACT_APP_VERSION: getEnv('REACT_APP_VERSION'),
};

export default env;
