import moment from 'moment-timezone';
import env from 'env';
import { sumBy, pick } from 'lodash';
import { ENTRY_POINTS } from './types';

const ID_TOKEN = 'idToken';
const MOCK_ID_TOKEN = 'mockIdToken';

export const ACH_PAYMENT_INFO = 'ach_payment_info';
export const HAS_REQUESTED_PAYMENTS = 'has_requested_payments';
export const PAYMENTS_SESSION_ID = 'paymentsSessionId';
export const STRIPE_READERS_URL = 'https://stripe.com/docs/terminal/readers';

export function hexToRgba(hex, opacity) {
  const code = hex.replace('#', '');

  const r = parseInt(code.substring(0, 2), 16);
  const g = parseInt(code.substring(2, 4), 16);
  const b = parseInt(code.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export const delay = ms => new Promise(res => setTimeout(res, ms));

export const getCookie = name => {
  let value = '; ' + document.cookie;
  let parts = value.split('; ' + name + '=');
  if (parts.length === 2)
    return parts
      .pop()
      .split(';')
      .shift();
};

/// Retrieves the bearer token
export const getBearerToken = () => {
  if (isNullOrWhitespace(document.cookie)) {
    return null;
  }

  // If mocked in, we want to pass in the mockIdToken so that our queries and connections
  // appear to be coming from a user that is a part of the organization we are mocked into.
  const mockToken = getCookie(MOCK_ID_TOKEN);
  const token = isNullOrWhitespace(mockToken) ? getCookie(ID_TOKEN) : mockToken;

  return token;
};

export const deleteCookie = (name, defaultPath) => {
  if (getCookie(name))
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=${
      defaultPath ? defaultPath : '/'
    }`;
};

const getCookieExpiration = expiresInSeconds => {
  const expirationDate = new Date(
    new Date().getTime() + expiresInSeconds * 1000
  ).toGMTString();

  return `expires=${expirationDate}`;
};

export const addCookie = (
  cookieName,
  cookieValue,
  path,
  expiresInSeconds,
  flags = {}
) => {
  let cookie = `${cookieName}=${cookieValue};path=${path ? path : '/'}`;

  if (expiresInSeconds) {
    const expiration = getCookieExpiration(expiresInSeconds);
    cookie = `${cookie};${expiration}`;
  }

  const flagCookiesArr = Object.entries(flags).map(flagEntry => {
    return `${flagEntry[0]}=${flagEntry[1]}`;
  });

  if (flagCookiesArr.length > 0) {
    cookie = `${cookie};${flagCookiesArr.join(';')}`;
  }

  document.cookie = cookie;
};

export const getParsedJsonCookie = cookeiName => {
  const cookie = getCookie(cookeiName);
  return cookie ? JSON.parse(cookie) : null;
};

export const cookiesEnabled = () => {
  if (navigator.cookieEnabled) return true;

  // set and read cookie
  document.cookie = 'cookietest=1';
  const cookiesEnabled = document.cookie.indexOf('cookietest=') !== -1;

  // delete cookie
  document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';

  return cookiesEnabled;
};

export const getCurrency = country => {
  switch (country) {
    case 'US':
      return 'usd';
    case 'AU':
      return 'aud';
    case 'CA':
      return 'cad';
    default:
      return 'usd';
  }
};

const getRedirectUrl = from => {
  const searchParams = new URLSearchParams(new URL(window.location).search);
  const redirectUrl = searchParams.get('redirectUrl');

  if (redirectUrl && from) {
    const url = new URL(redirectUrl);
    let redirectUrlParams = new URLSearchParams(url.search);
    redirectUrlParams.append('from', from);
    url.search = redirectUrlParams.toString();
    return url.toString();
  }

  if (redirectUrl) return redirectUrl;

  return null;
};

export const returnToApp = from => {
  const redirectUrl = getRedirectUrl(from);
  if (redirectUrl) {
    window.location.assign(redirectUrl);
  } else {
    window.location.assign(env.REACT_APP_ONBOARDING_COMPLETED_REDIRECT_URL);
  }
};

export const parseHtmlCharacters = string => {
  const parser = new DOMParser();
  const dom = parser.parseFromString(string, 'text/html');
  return dom.body.textContent;
};

export function formatPhone(number) {
  var cleaned = ('' + number).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return ['(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
}

export function formatPhoneNumber(number, country) {
  if (!number) return null;
  const trimmed = trimNumber(number, country);
  const cleaned = ('' + trimmed).replace(/\D/g, '');

  let match;

  switch (country) {
    case 'US':
      match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) return ['(', match[2], ') ', match[3], '-', match[4]].join('');
      break;
    case 'AU':
      if (cleaned.match(/^(\d{9})$/)) {
        return `0${cleaned.replace(/(\d{1})(\d{4})(\d{4})/, '$1 $2 $3')}`;
      }
      return cleaned;
    case 'CA':
      match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) return [match[2], '-', match[3], '-', match[4]].join('');
      break;
    default:
      return null;
  }
  return null;
}

function trimNumber(number, country) {
  // Check if it has a '+` prefix country code
  if (number[0] !== '+') return number;

  switch (country) {
    case 'US':
    case 'CA':
      return number.substring(2);
    case 'AU':
      return number.substring(3);
    default:
      return number;
  }
}

export function formatPhoneWithoutParen(number) {
  var cleaned = ('' + number).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return [match[2], '-', match[3], '-', match[4]].join('');
  }
  return null;
}

export function maybePluralize(string, cond, suffix = 's') {
  if (cond) return `${string}${suffix}`;
  return string;
}

export function computeExpiration(
  expiresAt,
  timezone = 'UTC',
  formatString = 'MMM DD, YYYY'
) {
  if (!expiresAt) {
    return {
      expiresAtDate: '',
      expired: false,
      hoursToExpire: null,
      daysToExpire: null,
    };
  }

  const expiresAtDate = moment.utc(expiresAt).tz(timezone);
  const now = moment.tz(timezone);
  const hoursToExpire = expiresAtDate.diff(now, 'hours');
  const expiresInCopy = (() => {
    if (hoursToExpire > 24) {
      let day = expiresAtDate.diff(now, 'days');
      return `Expires in ${day} ${maybePluralize('day', day > 1)}`;
    } else if (hoursToExpire > 0)
      return `Expires in ${hoursToExpire} ${maybePluralize(
        'hour',
        hoursToExpire > 1
      )}`;
    let min = expiresAtDate.diff(now, 'minutes');
    if (min > 0)
      return `Expires in ${min} ${maybePluralize('minute', min > 1)}`;
    return null;
  })();

  return {
    daysToExpire: expiresAtDate.diff(now, 'days'),
    expired: expiresAtDate.isBefore(),
    expiresAtDate: expiresAtDate.format(formatString),
    expiresInCopy,
    hoursToExpire,
  };
}

export const screenSlideIn = (
  screen,
  direction = 'horizontal',
  horizontalDirection = 'left'
) => {
  if (screen && screen.current && screen.current.style) {
    if (direction === 'horizontal') {
      if (horizontalDirection === 'left') {
        screen.current.style.left = 0;
      } else {
        screen.current.style.right = 0;
      }
    } else screen.current.style.top = 0;
  }
};

export const screenSlideOut = (
  screen,
  direction = 'horizontal',
  horizontalDirection = 'left'
) => {
  if (screen && screen.current && screen.current.style) {
    if (direction === 'horizontal') {
      if (horizontalDirection === 'left') {
        screen.current.style.left = '100vw';
      } else {
        screen.current.style.right = '-100vw';
      }
    } else screen.current.style.top = '100vh';
  }
};

export const formatPennyAmount = (a, opts) => {
  const toFixed = opts?.toFixed ?? 2;
  const currency = opts?.currency ?? 'usd';
  const isNegative = a < 0;

  if (isNaN(a)) a = 0;
  a = Math.abs(a);
  a /= 100;
  a = a.toFixed(toFixed);

  let symbol = '$';

  if (currency === 'aud') symbol = 'A$';

  return `${isNegative ? '-' : ''}${symbol}${a.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ','
  )}`;
};

export const formatPercentage = a => {
  var asFloat = parseFloat(a);
  if (isNaN(asFloat)) asFloat = 0;
  asFloat *= 100;
  asFloat = asFloat.toFixed(2);

  return `${asFloat.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}%`;
};

export const formatTimeDisplay = time => {
  return time
    .format('MMM D, YYYY, h:mm A z')
    .replace('AM', 'a.m.')
    .replace('PM', 'p.m.');
};

/**
 * Checks the given string to see if it's null or whitespace.
 * @param {!String} item
 * @returns
 */
export const isNullOrWhitespace = item => {
  return (item ?? '').toString().trim().length === 0;
};

export const formatRecurringAmountDue = ({
  template: { amount },
  totalRemaining,
  totalToSend,
}) => {
  if (totalToSend === 0) return formatPennyAmount(amount);
  return totalRemaining === 0
    ? formatPennyAmount(0)
    : formatPennyAmount(amount);
};

export const parseQueryString = queryString => {
  const urlSearchParams = new URLSearchParams(queryString);

  let params = {};

  urlSearchParams.forEach(function(value, key) {
    params[key] = value;
  });

  return params;
};

export const encodeQueryString = params => {
  const urlSearchParams = new URLSearchParams();
  for (const [key, value] of Object.entries(params)) {
    if (!value) {
      continue;
    } else {
      urlSearchParams.set(key, value);
    }
  }

  return urlSearchParams.toString();
};

export const upperCase = str => str.charAt(0).toUpperCase() + str.slice(1);

export const titleCase = str => {
  if (str) {
    return str
      .toLowerCase()
      .split(' ')
      .map(e => e.replace(e[0], e[0].toUpperCase()))
      .join(' ');
  }
  return str;
};

export const formatCard = card => {
  switch (card?.toUpperCase()) {
    case 'VISA':
      return 'Visa';
    case 'DINERS_CLUB':
      return 'Diners Club';
    case 'DISCOVER':
      return 'Discover';
    case 'JCB':
      return 'JCB';
    case 'MASTERCARD':
      return 'Mastercard';
    case 'UNION_PAY':
      return 'UnionPay';
    case 'AMERICAN_EXPRESS':
      return 'Amex';
    default:
      return '';
  }
};

export const nthMask = value => {
  if (parseInt(value) === 32) return 'last day';
  const digits = value.toString().split('');
  const lastDigit = +digits[digits.length - 1];
  const outliers = [11, 12, 13];

  if (outliers.includes(value)) return `${value}th`;

  switch (lastDigit) {
    case 1:
      return `${value}st`;
    case 2:
      return `${value}nd`;
    case 3:
      return `${value}rd`;
    default:
      return `${value}th`;
  }
};

export const getBrowserTimeZone = () => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';
  } catch (error) {
    return 'UTC';
  }
};

export function getReadersFromLocations(locations) {
  let locsWithMobileReaders = [];
  let locsWithStationaryReadersEnabled = [];
  let stationaryReaders = [];

  if (locations !== undefined) {
    locations.forEach(location => {
      const { hasMobileReader, featureFlags, readers } = location;

      if (hasMobileReader) locsWithMobileReaders.push(location);
      if (featureFlags?.stationaryReadersEnabled)
        locsWithStationaryReadersEnabled.push(location);
      if (readers) readers.forEach(r => stationaryReaders.push(r));
    });
  }
  return {
    locsWithMobileReaders,
    locsWithStationaryReadersEnabled,
    stationaryReaders,
  };
}

const EMAIL_SUBJECT = 'Podium Card Reader Troubleshooting';
export const SUPPORT_EMAIL = 'support@podium.com';

export const openReadersTroubleshooting = () =>
  window.open(
    `https://help.podium.com/hc/en-us/sections/360012622933-Collecting-Payments-with-Card-Readers`
  );
export const openSupportEmail = () =>
  window.open(`mailto:${SUPPORT_EMAIL}?subject=${EMAIL_SUBJECT}`);

/**
 * Parses a string of query params to return a well formed object.
 * @param {*} windowLocationSearch
 * @returns {Object}
 * {
    ACCOUNT_UID,
    CHANNEL_IDENTIFIER,
    CHANNEL_TYPE,
    COMPOSER_CONTAINS_TEXT,
    CONTEXT_PAGE,
    CONVERSATION_UID,
    COUNTRY_CODE,
    CUSTOMER_NAME,
    ENTRY_POINT,
    ESTIMATE_UID,
    EXPIRY,
    INVOICE_AMOUNT,
    INVOICE_NUMBER,
    INVOICE_NUMBER_READ_ONLY,
    INVOCIE_ORIGIN,
    IS_RECURRING_REQUEST,
    IS_WEB,
    LINE_ITEMS,
    LINE_ITEMS_READ_ONLY,
    LOCATION_UID,
    ORGANIZATION_UID,
    PAYMENT_FLOW,
    PREFERS_OLD_COMPOSER_APP_EXPERIENCE,
    READER_UID,
    RECURRING,
    SIGNATURE,
    USER_UID,
  }
 */
export const getConstantsFromQueryParams = windowLocationSearch => {
  const search = windowLocationSearch ?? '';

  const queryParams = new URLSearchParams(search);

  const has = value => queryParams.has(value);

  const parse = (value, defaultValue = null) => {
    const queryValue = queryParams.get(value);

    if (isNullOrWhitespace(queryValue) || queryValue === 'undefined') {
      return defaultValue;
    }

    return queryValue;
  };

  const parseBool = (value, defaultValue = null) => {
    const queryValue = parse(value);
    if (!queryValue) {
      return defaultValue;
    }

    return queryValue === 'true' ? true : false;
  };

  const parseJSON = (value, key, defaultValue = null) => {
    try {
      const queryValue = JSON.parse(decodeURIComponent(parse(value)));

      if (!queryValue) return defaultValue;
      else if (!key) return queryValue;
      else if (!queryValue[key]) return defaultValue;
      else return queryValue[key];
    } catch (e) {
      // Ignore errors with JSON.parse and just return default value
      console.error(`error parsing JSON '${value}' in query params`, e);

      return defaultValue;
    }
  };

  return {
    ACCOUNT_UID: parse('accountUid'),
    CHANNEL_IDENTIFIER: parse('channelIdentifier'),
    CHANNEL_TYPE: parse('channelType'),
    COMPOSER_CONTAINS_TEXT: parseBool('composerContainsText', false),
    CONTEXT_PAGE: parse('contextPage'),
    CONVERSATION_UID: parse('conversationUid'),
    COUNTRY_CODE: parse('countryCode'),
    CUSTOMER_NAME: parse('contactName'),
    ENTRY_POINT:
      parse('entryPoint') ||
      (has('composerAppMetadata') ? ENTRY_POINTS.INBOX : null),
    ESTIMATE_UID:
      parse('estimateUid') || parseJSON('composerAppMetadata', 'estimateUid'),
    EXPIRY: parse('expiry'),
    INVOICE_AMOUNT:
      parse('invoiceAmount') ||
      parseJSON('composerAppMetadata', 'invoiceAmount', 0),
    INVOICE_NUMBER: parse('invoiceNumber'),
    INVOICE_NUMBER_READ_ONLY: parseBool('invoiceNumberReadOnly'),
    INVOICE_ORIGIN: parseJSON('composerAppMetadata', 'invoiceOrigin', null),
    IS_RECURRING_REQUEST: parseBool('isRecurringRequest'),
    IS_WEB: parseBool('isWeb'),
    LINE_ITEMS: parseJSON('lineItems'),
    LINE_ITEMS_READ_ONLY: parseBool('lineItemsReadOnly'),
    LOCATION_UID: parse('locationUid') ?? parse('location_uid'),
    ORGANIZATION_UID: parse('organizationUid'),
    PAYMENT_FLOW: parse('paymentFlow'),
    // Delete after full roll-out of Universal Payments in Inbox:
    PREFERS_OLD_COMPOSER_APP_EXPERIENCE: parseBool(
      'prefersOldComposerAppExperience',
      false
    ),
    READER_UID: parse('readerUid'),
    RECURRING: parseJSON('recurring'),
    SIGNATURE: parse('signature'),
    USER_UID: parse('userUid'),
  };
};

export const setURLQueryParam = o => {
  if (typeof o === 'object') {
    const key = Object.keys(o)[0] || null;
    const value = Object.values(o)[0] || null;
    if (key && value) {
      let search = new URLSearchParams(window.location.search);
      search.set(key, value);
      window.location.search = search;
    }
  }
};

/**
 * Helper function that takes the `invoiceForm` state and transforms its `lineItems`
 * to map to the expected structure gringotts requires
 */
export const transformInvoiceStateLineItems = ({
  lineItems,
  allowNullDescription = false,
}) => {
  return lineItems.map((li, i) => {
    var _li = pick(li, ['amount', 'description', 'requiresShipping']);
    if (isNullOrWhitespace(_li.description)) {
      // This feels bad but Gringotts doesn't allow empty descriptions on line items.
      // Sometimes we want to allow empty strings, such as encoding query params.
      _li.description = allowNullDescription
        ? ''
        : 'READER_PORTAL_NO_DESCRIPTION';
    }
    _li.amount = parseInt(li.amount);
    return _li;
  });
};

/**
 * Takes common invoice attributes and encodes them into a urlSearchParams string.
 * The strings structure aligns with the domain contract of the returned object
 * when calling the getConstantsFromQueryParams
 */
export const encodeInvoiceAttrsToUrlSearchParams = ({
  accountUid,
  customer,
  estimateUid,
  entryPoint,
  invoiceNumber,
  isRecurringRequest,
  lineItems: _lineItems,
  paymentFlow,
  podiumConversationUid,
  podiumLocationUid,
  recurring: _recurring,
}) => {
  const lineItems = JSON.stringify(
    transformInvoiceStateLineItems({
      lineItems: _lineItems,
      allowNullDescription: true,
    })
  );
  const recurring = encodeURIComponent(JSON.stringify(_recurring));
  const lineItemsEmpty = lineItems === '[{}]' || lineItems === '[]';

  const queryObject = {
    accountUid,
    channelIdentifier: customer?.channelUniqueIdentifier || null,
    channelType: customer?.channelType || null,
    contactName: customer?.name || null,
    conversationUid: podiumConversationUid,
    estimateUid,
    entryPoint,
    invoiceNumber,
    isRecurringRequest,
    lineItems: lineItemsEmpty ? null : encodeURIComponent(lineItems),
    locationUid: podiumLocationUid,
    paymentFlow,
    recurring,
  };

  var queryParams = new URLSearchParams();

  for (const key in queryObject)
    queryObject[key] && queryParams.set(key, queryObject[key]);
  return `?${queryParams.toString()}`;
};

/**
 * Takes lineItems from an `invoiceForm` state and sums the amount of each
 */
export const getTotalLineItemsAmount = lineItems =>
  sumBy(lineItems, li => (li.amount ? parseInt(li.amount) : 0));

/**
 * Takes the result of `getConstantsFromQueryParams` and returns its own object
 * in the form that the universal payments invoicing context accepts for its
 * invoiceForm value
 */
export const getInvoiceFormQueryParamsDefaults = ({
  CHANNEL_IDENTIFIER,
  CHANNEL_TYPE,
  CUSTOMER_NAME,
  CONVERSATION_UID,
  INVOICE_AMOUNT,
  INVOICE_NUMBER,
  IS_RECURRING_REQUEST,
  LINE_ITEMS,
  LOCATION_UID,
  RECURRING,
}) => {
  const noLineItems =
    INVOICE_AMOUNT > 0
      ? [
          {
            amount: INVOICE_AMOUNT,
            description: null,
            requiresShipping: false,
          },
        ]
      : [];

  return {
    customer: {
      channelType: CHANNEL_TYPE,
      channelUniqueIdentifier: CHANNEL_IDENTIFIER,
      email: CHANNEL_TYPE === 'email' ? 'locked' : null,
      name: CUSTOMER_NAME,
      phoneNumber: CHANNEL_TYPE === 'phone' ? 'locked' : null,
    },
    invoiceNumber: INVOICE_NUMBER,
    isRecurringRequest: IS_RECURRING_REQUEST,
    lineItems: LINE_ITEMS ? LINE_ITEMS : noLineItems,
    podiumConversationUid: CONVERSATION_UID ? CONVERSATION_UID : null,
    podiumLocationUid: LOCATION_UID ? LOCATION_UID : null,
    recurring: RECURRING,
  };
};

/**
 * Returns an collection of data for calculated invoice service charges.
 * Typically used for display purposes.
 * @param {*} Object -  
 `{  
    invoiceServiceChargeItems=[
      {
        amount,
        name,
        flatChargeAmount,
        name,
        percentageChargeRate,
        serviceChargeClassification,
        serviceChargeType,
      }
    ]
  }`
 * @returns Object
 `{
    totalAmount,
    appliedPercentages,
    appliedFlatCharges,
    appliedServiceCharges: [
      {
        amount,
        isPercentageRate,
        label,
        rate,
      }
    ]
  }`
 */
export const getServiceChargeDetailsFromInvoice = invoice => {
  const serviceCharges = invoice?.invoiceServiceChargeItems || [];

  let totalAmount = 0;
  let appliedPercentages = 0;
  let appliedFlatCharges = 0;

  serviceCharges.forEach(
    ({ amount, percentageChargeRate, flatChargeAmount }) => {
      totalAmount += amount || 0;
      appliedPercentages += parseFloat(percentageChargeRate || 0);
      appliedFlatCharges += flatChargeAmount || 0;
    }
  );

  // Change to display values
  appliedPercentages = Math.round(appliedPercentages * 100) / 100;
  appliedPercentages = `${appliedPercentages.toFixed(2)}%`;
  appliedFlatCharges = formatPennyAmount(appliedFlatCharges);
  totalAmount = formatPennyAmount(totalAmount);

  // Calculate applied service charges
  const appliedServiceCharges = serviceCharges.map(s => {
    let rate = '';
    let isPercentageRate = false;
    if (s.flatChargeAmount) {
      rate = formatPennyAmount(s.flatChargeAmount);
    } else if (s.percentageChargeRate) {
      isPercentageRate = true;
      rate = `${s.percentageChargeRate}%`;
    }

    return {
      isPercentageRate,
      label: s.name,
      rate,
      amount: formatPennyAmount(s.amount),
    };
  });

  // Sort alphabetically
  appliedServiceCharges.sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    } else if (a.label > b.label) {
      return 1;
    } else {
      return 0;
    }
  });

  return {
    appliedServiceCharges,
    totalAmount,
    appliedPercentages,
    appliedFlatCharges,
  };
};

export const formatDollars = (dollarString, currency = 'USD') => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  });

  return formatter.format(dollarString);
};

export const sortLocations = locations => {
  return [...locations].sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    } else if (a.name > b.name) {
      return 1;
    } else {
      return 0;
    }
  });
};

export const isAustralianUser = () => {
  const browserTimeZone = getBrowserTimeZone();

  // Those time zones were mapped from
  // node_modules/moment-timezone/data/meta/latest.json
  return [
    'Australia/Lord_Howe',
    'Antarctica/Macquarie',
    'Australia/Hobart',
    'Australia/Melbourne',
    'Australia/Sydney',
    'Australia/Broken_Hill',
    'Australia/Brisbane',
    'Australia/Lindeman',
    'Australia/Adelaide',
    'Australia/Darwin',
    'Australia/Perth',
    'Australia/Eucla',
  ].includes(browserTimeZone);
};

export const formatUserName = user => {
  const firstName = user.firstName || '';
  const lastName = user.lastName || '';
  return `${firstName} ${lastName}`;
};
