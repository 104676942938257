import { useQuery, gql } from '@apollo/client';

export const GET_CURRENT_USER_QUERY = gql`
  query GetCurrentUser {
    currentUser {
      id: uid
      uid
      firstName
      lastName
      phone
      email
      organizationUid
      platformLocations {
        id: uid
        country
      }
    }
  }
`;

function useCurrentUserQuery(opts) {
  const { data, loading } = useQuery(GET_CURRENT_USER_QUERY, opts);

  return {
    currentUser: data?.currentUser,
    loading,
  };
}

export function getCurrentUserMock() {
  return {
    request: {
      query: GET_CURRENT_USER_QUERY,
    },
    result: {
      data: {
        currentUser: {
          id: '64b92b95-e98f-43da-b2fc-31e67bf0229e',
          uid: '64b92b95-e98f-43da-b2fc-31e67bf0229e',
          firstName: 'Newt',
          lastName: 'Scamander',
          phone: '+18015533366',
          email: 'newt.s@ministry.org',
          organizationUid: '7e0df439-6d57-48df-bc20-8e7679b64993',
          platformLocations: [
            {
              id: '9ebc4194-14a5-4ab9-a532-eaae96bfc366',
              country: 'US',
            },
          ],
        },
      },
    },
  };
}

export default useCurrentUserQuery;
