import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { colors, IconLock, fontFamily } from '@podiumhq/podium-ui';

import { hexToRgba } from 'shared/util';
import { IconButton, COLORS } from '@podiumhq/design-system';

export const StripeComponentsStyles = () => {
  return {
    options: {
      style: {
        base: {
          fontSize: '16px',
          borderColor: colors.steel,
          color: colors.steel,
          letterSpacing: '0.05em',
          fontFamily: `${fontFamily} 400`,

          '::placeholder': {
            color: colors.lightSteel,
          },
        },
      },
    },
  };
};

export const BackToInvoicePageButtonWrapper = styled.div`
  text-align: initial;
  padding-top: 28px;
  margin-bottom: 24px;
`;

export const BillDetailLabel = styled.div`
  color: ${colors.mineShaft};
  font-weight: 600;
  height: 16px;
  margin-right: 12px;
`;

export const PaymentMethodSelectionWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-left: 32px;
  padding-right: 37px;
  padding-bottom: 32px;
  margin: 0px auto;
  max-width: 415px;
  position: relative;

  &.display-enter {
    opacity: 0;
    transform: translateX(100%);
    transition-timing-function: ease-in;
  }

  &.display-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }

  &.display-exit {
    opacity: 1;
  }

  &.display-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 300ms, transform 300ms;
  }
`;

export const DashedSeparator = styled.div`
  border-bottom-style: dashed;
  border-bottom-color: ${colors.iron};
  border-bottom-width: 2px;
`;

export const EmailReceiptForm = styled.form`
  display: flex;
  width: 100%;
  margin-bottom: 24px;
`;

export const EmailReceiptButton = styled(IconButton)`
  margin-left: 8px;
`;

export const Last4Cell = styled.div`
  display: flex;
  align-items: center;
`;

export const Dot = styled.div`
  background: ${colors.mineShaft};
  height: 5px;
  width: 5px;
  border-radius: 50%;
  margin: 2px;
  ${props => props.first && `margin-left: 5px`}
  ${props => props.last && `margin-right: 5px`}
`;

export const LayoutWrapper = styled.div`
  font-family: ${fontFamily};
  margin: 0 auto 0 auto;
  max-width: 415px;
  width: 100vw;
  min-height: 100vh;
  position: relative;
  /* When exiting a transition, hide */
  &.display-exit {
    display: none;
  }
`;

export const InvoiceContainer = styled.main`
  box-sizing: border-box;
  height: 100%;
  margin-top: 16px;
  padding: 0 30px;
  position: relative;
  display: ${props => (props.hide ? 'none' : 'block')};
`;

export const InvoiceHeader = styled.div`
  background: ${colors.mystic};
  border-radius: 0px;
  display: flex;
  height: 72px;
  justify-content: center;
  position: relative;
  width: 100%;
`;

export const AccountLogo = styled.div`
  background: url(${props => props.logoUrl}) repeat white;
  background-position: center;
  background-size: 89px;
  border-radius: 50%;
  border: solid 1px ${colors.whiteSmoke};
  height: 89px;
  margin-top: 27px;
  width: 89px;
`;

export const AccountLogoLoading = styled.div`
  border-radius: 50%;
  height: 89px;
  margin-top: 27px;
  width: 89px;
  background: ${colors.iron};
`;

export const AccountDetails = styled.div`
  color: ${colors.mineShaft};
  display: flex;
  flex-direction: column;
  font-size: 16px;
  justify-content: center;
  letter-spacing: -0.34px;
  line-height: 20px;
  margin-top: 32px;
  padding-bottom: 16px;
  padding: 24px 30px 16px 30px;
  text-align: center;
`;

export const AccountName = styled.div`
  font-weight: 600;
`;

export const AccountAddress = styled.div`
  font-weight: 400;
`;

export const SeparationLine = styled.div`
  background: ${colors.mystic};
  border-radius: 0px;
  height: 2px;
  width: 100%;
  margin-top: ${props => (props.marginTop ? props.marginTop : '')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '')};
`;

export const SeparationLineWrapper = styled.div`
  display: flex;
  .orInBetweenLine {
    padding-left: 5px;
    padding-right: 5px;
  }
`;

export const InvoiceDetailsWrapper = styled.div`
  position: relative;
`;

export const CenterColumn = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const ExtraMargin = styled.div`
  margin-left: 33.5px;
  margin-right: 27.5px;
`;

export const Amount = styled.div`
  color: ${colors.mineShaft};
  font-size: 40px;
  font-weight: 500;
  height: 40px;
  letter-spacing: 0px;
  line-height: 40px;
  margin-bottom: 7px;
  margin-top: 15.5px;
  text-align: center;
  width: 100%;
  @media screen and (max-width: 260px) {
    padding-bottom: 80px;
  }
`;

export const InvoiceDate = styled.div`
  font-size: 16px;
  font-weight: 500;
  height: 18px;
  letter-spacing: 0px;
  line-height: 18px;
  text-align: center;
  @media screen and (max-width: 260px) {
    padding-bottom: 25px;
  }
`;

export const Details = styled.div`
  padding: 23px 0;
`;

export const DetailRow = styled.div`
  color: ${props => (props.color ? props.color : colors.mineShaft)};
  display: flex;
  font-size: 16px;
  margin-bottom: 9px;
  padding-top: ${props => (props.paddingTop ? props.paddingTop : '')};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : '')};
  text-align: ${props => (props.center ? 'center' : 'left')};
  width: 100%;
`;

export const DetailLabel = styled.div`
  color: ${props => (props.dark ? colors.mineShaft : colors.jumbo)};
  flex: ${props => props.flex || 0.75};
  font-weight: ${props => (props.bold ? `600` : `normal`)};
  height: 16px;
`;

export const DetailValue = styled.div`
  flex: 1;
  font-weight: normal;
`;

export const LineItemAmount = styled.div`
  text-align: right;
  width: 100%;
  font-weight: ${props => (props.bold ? `500` : `normal`)};
`;
export const LineItemDescription = styled.div(
  ({ width }) => `
  width: ${width || '270px'};
  font-weight: ${props => (props.bold ? `500` : `normal`)};
`
);

export const CircledIcon = styled.div`
  align-items: center;
  height: 48px;
  width: 48px;
  background: ${props => props.backgroundColor || colors.whiteSmoke};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin-bottom: ${props => props.marginBottom || '18px'};
  margin-top: ${props => props.marginTop || '18px'};
  margin-left: 18px;
  margin-right: 18px;
`;

export const PaymentMessage = styled.div`
  color: ${colors.mineShaft};
  font-size: ${props => (props.bold ? `18px` : `16px`)};
  font-weight: ${props => (props.bold ? 600 : `normal`)};
  letter-spacing: 0px;
  text-align: center;
  width: ${props => props.width || 291}px;
`;

export const StripeWrapper = styled.div`
  .StripeElement {
    background-color: ${colors.white};
    border-radius: 4px;
    border: 1px solid ${props => (props.error ? colors.poppyRed : colors.steel)};
    box-sizing: border-box;
    height: 40px;
    margin-top: 8px;
    padding: 10px 12px;
  }

  .StripeElement--focus {
    background: ${colors.white};
    border-radius: 4px;
    border: 1px solid ${colors.cobaltBlue};
    box-shadow: 0px 0px 0px 2px rgba(76, 118, 224, 0.25);
  }

  .StripeElement--invalid {
    border-radius: 4px;
    border: 1px solid ${colors.poppyRed};
    box-shadow: none;
  }

  .StripeElement--error {
    border-radius: 4px;
    border: 1px solid ${colors.poppyRed};
    box-shadow: none;
  }
`;

export const Label = styled.div`
  bottom: 5px;
  color: ${colors.mineShaft};
  font-size: 14px;
  font-weight: 500;
  height: 11px;
  letter-spacing: 0px;
  position: relative;
  text-align: left;
`;

export const Error = styled.div`
  color: ${colors.poppyRed};
  font-size: 12px;
  letter-spacing: 0px;
  margin-top: 8px;
  position: relative;
  text-align: left;
`;

export const InputWrap = ({ labelName, errorMessage, children }) => (
  <label style={{ flex: 1, position: 'relative' }}>
    <Label>{labelName}</Label>
    {children}
    {errorMessage && <Error>{errorMessage}</Error>}
  </label>
);

export const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  // Hack podium-ui input label
  & > label > div > span {
    color: ${colors.mineShaft};
  }
`;

export const Footer = styled.footer`
  bottom: 0px;
  color: ${colors.jumbo};
  font-size: 12px;
  right: 0;
  text-align: center;
  left: 0;
  position: sticky;
  background: ${colors.white};
`;

export const FooterWithBorderTopNew = styled(Footer)`
  max-width: 415px;
  margin: 0px auto;

  &:before,
  &:after {
    position: absolute;
    background: ${COLORS.grey.lightest};
    height: 1px;
    content: '';
    width: 100%;
    display: block;
    transform: translateX(30px);
    top: 0;
  }

  &:after {
    transform: translateX(-30px);
  }
`;

export const Section = styled.section`
  padding: ${props =>
    props.savedPayment ? '10px 0px 20px 0px' : '10px 5px 20px 5px'};
  margin-top: ${props => (props.marginTop ? '20px' : '0')};
  padding-bottom: ${props => props.paddingBottom};
`;

const StatusImage = styled.div`
  height: 100%;
  width: 100%;
`;

export const StatusImageFailed = styled(StatusImage)`
  background-image: url('https://assets.podium.com/images/error.svg');
`;

export const TryAgainButton = styled(Link)`
  background-color: ${colors.whiteSmoke};
  border-radius: 3px;
  color: ${colors.cobaltBlue};
  font-size: 16px;
  font-weight: 600;
  margin-top: 4px;
  padding: 12px 16px 14px;
  text-decoration: none;
`;

export const TestHeader = styled.div`
  background-color: ${colors.lightSteel};
  color: ${colors.white};
  display: flex;
  justify-content: center;
  padding: 6px;
`;

export const LearnMore = styled.div`
  cursor: pointer;
  color: ${colors.steel};
  font-size: 14px;
  font-weight: 600;
  margin: 6px 0 14px 0;
`;

export const LearnMoreWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 53px;
`;

export const LockIcon = styled(IconLock)`
  fill: ${colors.white};
`;

export const SaveCardOnFileWrapper = styled.div`
  padding: 18px 25px 16px 0px;
  margin-bottom: 32px;
`;

export const CreditCardInfoWrapper = styled.div(
  ({ disabled }) => `
  cursor: ${disabled ? 'default' : 'pointer'};
  background: rgb(255, 255, 255);
  border: 1px solid rgb(228, 233, 240);
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 0px 24px;
  background: ${disabled ? hexToRgba(colors.mystic, 0.5) : colors.white};
`
);

export const CreditCardList = styled.div`
  margin-top: 22px;
`;

export const DifferentCardOption = styled.div`
  cursor: pointer;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(228, 233, 240);
  border-radius: 3px;
  padding: 0px 24px;
  margin-bottom: 28px;
`;

export const CVCInputWrapper = styled.div`
  display: flex;
  padding: 8px 0px;
`;

export const RadioWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const IconChevronWrapper = styled.div`
  margin: 16px 0;
`;

export const CardDetailsWrapper = styled.div`
  max-width: 184px;
`;

export const ExpiredCardWarning = styled.div`
  color: ${colors.poppyRed};
  display: flex;
  font-weight: normal;
  margin-top: 8px;

  > :last-child {
    margin-left: 0.5em;
  }
`;

export const UpdateCardButton = styled.div`
  text-decoration: underline;
  font-weight: 500;
`;

//The height is hardcoded so that as the modal
//state and message changes, its size does not
export const ModalWrapper = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  line-height: 24px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 207px;
`;

export const ExpiresAt = styled.p`
  font-size: 12px;
  font-weight: normal;
  line-height: 12px;
  color: ${colors.jumbo};
  margin: 14px 0 14px 0;
`;

export const RecurringRequestPill = styled.div`
  color: rgb(99, 114, 125);
  font-size: 14px;
  display: inline-flex;
  font-weight: 400;
  width: 100%;

  .icon-header-wrapper {
    display: inline-flex;
    margin: 20px auto auto;
  }

  .icon {
    display: inline-block;
    margin: auto 4px auto auto;
  }

  .header {
    display: inline-block;
    font-weight: 500;
    margin: auto 0;
  }
`;

export const SavedCardsTipping = styled.div`
  margin-bottom: 1.25rem;
`;

export const ManageSavedCardsWrapper = styled.div`
  padding: 18px 0;
  border-top: 1px solid ${COLORS.silver.base};
`;
