import { Component, isValidElement } from 'react';

/**
 * inspired by https://mathiasbynens.be/notes/globalthis
 */
export function getGlobalObject<T>(): T {
  if (typeof globalThis === 'object') {
    return (globalThis as unknown) as T;
  }
  Object.defineProperty(Object.prototype, '_dd_temp_', {
    get() {
      return this;
    },
    configurable: true,
  });
  // @ts-expect-error _dd_temp is defined using defineProperty
  let globalObject: unknown = _dd_temp_;
  // @ts-expect-error _dd_temp is defined using defineProperty
  delete Object.prototype._dd_temp_;
  if (typeof globalObject !== 'object') {
    // on safari _dd_temp_ is available on window but not globally
    // fallback on other browser globals check
    if (typeof self === 'object') {
      globalObject = self;
    } else if (typeof window === 'object') {
      globalObject = window;
    } else {
      globalObject = {};
    }
  }
  return globalObject as T;
}

type FallbackRenderer = (error: Error) => React.ReactNode;

interface ErrorBoundaryState {
  hasError: boolean;
  error?: Error;
  prevScope: string | undefined;
}

type ErrorBoundaryProps = React.PropsWithChildren<{
  fallback: React.ReactNode | FallbackRenderer;
  scope?: string;
}>;

/**
 * ErrorBoundary component sends enriched errors to RUM.
 */
export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  static defaultProps = {
    scope: 'error-boundary',
  };

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, prevScope: props.scope };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  static getDerivedStateFromProps(
    props: ErrorBoundaryProps,
    state: ErrorBoundaryState
  ) {
    if (state.prevScope !== props.scope) {
      return {
        hasError: false,
        error: undefined,
        prevScope: props.scope,
      };
    }

    return state;
  }

  componentDidCatch(error: Error) {
    const RumGlobal = getGlobalObject<Window>().DD_RUM;
    if (RumGlobal) {
      RumGlobal.addError(error, {
        scope: this.props.scope,
      });
    }
  }

  render() {
    const { hasError, error } = this.state;
    const { fallback } = this.props;

    if (!hasError || !error) {
      return this.props.children;
    }

    if (isValidElement(fallback) || typeof fallback === 'string') {
      return fallback;
    } else if (typeof fallback === 'function') {
      return fallback(error);
    }

    return null;
  }
}
