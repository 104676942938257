import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  createHttpLink,
  DocumentNode,
} from '@apollo/client';
import { createLink } from 'apollo-absinthe-upload-link';
import { setContext } from '@apollo/client/link/context';
import { getIdToken } from '@podiumhq/foe-hammer';
import { getCookie } from 'shared/util';
import {
  CUSTOMER_UID_COOKIE,
  VERIFICATION_CODE_COOKIE,
  getTextCodeAuthenticationHeader,
} from 'shared/textAuthUtils';
import env from 'env';
import PhoenixChannelLink from './phoenixChannelLink';
import { DefinitionNode } from 'graphql';

const isSubscription = (definition: DefinitionNode) =>
  definition.kind === 'OperationDefinition' &&
  definition.operation === 'subscription';

/**
 * Returns true if documentNode has a subscription or false otherwise
 */
const hasSubscription = (documentNode: DocumentNode) =>
  documentNode.definitions.some(isSubscription);

const cache = new InMemoryCache();

const httpLink = createHttpLink({
  uri: `${env.REACT_APP_GRINGOTTS_URI}/graphql`,
});

const authLink = setContext(async (_, { headers }) => {
  const idToken = await getIdToken(env.REACT_APP_ACCOUNTS_URL);

  if (idToken) {
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${idToken}`,
      },
    };
  } else {
    console.error('[Auth error]: No idToken returned from auth library');
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: cache,
});

export default client;

export const uploadClient = new ApolloClient({
  link: createLink({
    uri: `${env.REACT_APP_GRINGOTTS_URI}/graphql`,
    credentials: 'include',
  }),
  cache: cache,
});

const publicHttpLink = ApolloLink.split(
  operation => {
    const verificationCodeCookie = getCookie(VERIFICATION_CODE_COOKIE);
    const customerUidCookie = getCookie(CUSTOMER_UID_COOKIE);
    if (verificationCodeCookie && customerUidCookie) {
      operation.setContext({
        headers: getTextCodeAuthenticationHeader(
          verificationCodeCookie,
          customerUidCookie
        ),
      });
    }

    return hasSubscription(operation.query);
  },
  new PhoenixChannelLink(
    env.REACT_APP_GRINGOTTS_PUBLIC_WEBSOCKET_URI,
    'Gringotts'
  ),
  createHttpLink({ uri: `${env.REACT_APP_GRINGOTTS_URI}/graphql_public` })
);

export const clientPublic = new ApolloClient({
  cache: cache,
  link: publicHttpLink,
  credentials: 'include',
});

const subscriptionsHttpLink = ApolloLink.split(
  operation => {
    return hasSubscription(operation.query);
  },
  new PhoenixChannelLink(env.REACT_APP_GRINGOTTS_WEBSOCKET_URI, 'Gringotts'),
  httpLink
);

export const clientWithSubscriptions = new ApolloClient({
  cache: cache,
  link: authLink.concat(subscriptionsHttpLink),
  credentials: 'include',
});
