import { useCallback, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { Snackbar } from '@podiumhq/podium-ui';
import styled from '@emotion/styled';
import { Portal } from '@podiumhq/design-system';

const SnackbarWrapper = styled.div`
  z-index: 100000;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    position: relative;
    left: 0;
    display: flex;
  }
`;

export const useSmartSnackbar = (
  { autoHideDuration } = {
    autoHideDuration: 2000,
  }
) => {
  const [isVisible, setIsVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);

  const showMessage = useMemo(
    () =>
      debounce(
        message => {
          setMessage(message);
          setIsVisible(true);
          setTimeout(() => {
            setIsVisible(false);
          }, autoHideDuration + 250);
        },
        autoHideDuration + 250,
        {
          leading: true,
          trailing: true,
        }
      ),
    [autoHideDuration]
  );

  const showInfoMessage = useCallback(
    message => {
      setIsError(false);
      showMessage(message);
    },
    [showMessage]
  );

  const showErrorMessage = useCallback(
    message => {
      setIsError(true);
      showMessage(message);
    },
    [showMessage]
  );

  return {
    external: {
      showInfoMessage,
      showErrorMessage,
    },
    internal: {
      autoHideDuration,
      isVisible,
      message,
      isError,
    },
  };
};

export const SmartSnackbar = ({
  isVisible,
  autoHideDuration,
  message,
  isError,
  isCenteredInPortal = false,
}) => {
  const getSnackbar = snackbar => {
    return isCenteredInPortal ? (
      <Portal>
        <SnackbarWrapper>{snackbar}</SnackbarWrapper>
      </Portal>
    ) : (
      snackbar
    );
  };
  return (
    <>
      {isVisible &&
        getSnackbar(
          <Snackbar error={isError} autoHideDuration={autoHideDuration}>
            <span>{message}</span>
          </Snackbar>
        )}
    </>
  );
};
