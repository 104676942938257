import React from 'react';
import { colors } from '@podiumhq/podium-ui';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="35"
      viewBox="0 0 30 35"
    >
      <g
        fill="none"
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        transform="translate(-252 -444) translate(-.5 94) translate(93.5 346) translate(159 4)"
      >
        <path
          fill={`${colors.iron}`}
          d="M14.637.275a1.18 1.18 0 00-.88.377L11.43 2.913l-2.638-.538c-.827-.172-1.334.387-1.5 1.077L6.775 6.52l-2.793 1.4c-.662.345-.848 1.034-.517 1.723l1.345 2.746-1.345 2.745c-.33.69.02 1.379.517 1.723l2.793 1.4.517 3.069c.166.689.838 1.249 1.5 1.076l3.104-.538 2.327 2.261a1.25 1.25 0 001.81 0l2.328-2.261 3.154.538c.662.173 1.335-.387 1.5-1.076l.466-3.07 2.844-1.399c.662-.344.797-1.034.466-1.723l-1.81-2.745 1.344-2.746c.331-.69.145-1.378-.517-1.723l-2.793-1.4-.517-3.068c-.165-.69-.838-1.25-1.5-1.077l-3.103.538-2.327-2.26a1.293 1.293 0 00-.931-.378zM5.12 19.441L0 30.155l5.948-.861 3 5.491 4.292-9.152-.31-.323-1.655-1.562-2.327.377c-1.49.172-2.98-.872-3.31-2.422l-.363-2.262H5.12zm19.55 0l-.517 2.262c-.33 1.378-1.469 2.422-2.793 2.422h-.362l-2.275-.377-1.655 1.562-.363.323 4.293 9.152 3-5.33 5.948.861-5.276-10.875z"
        />
        <circle cx="15" cy="12.778" r="7.222" stroke={`${colors.white}`} />
      </g>
    </svg>
  );
}

export default Icon;
