import { ApolloProvider } from '@apollo/client';
import { PaymentAchRedirectLanding } from 'shared/components/PaymentAchRedirectLanding';
import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import GlobalStyles from 'shared/components/GlobalStyles';
import { GlobalSmartSnackbar } from 'shared/components/SmartSnackbar';
import AmplitudeProvider from 'shared/AmplitudeProvider';
import { clientPublic } from 'shared/apollo-client';
import { ErrorBoundary } from './ErrorBoundary';

const CancelPaymentRequest = lazy(() =>
  import('embedded/CancelPaymentRequest')
);
const CurrentFees = lazy(() => import('apps/CurrentFees'));
const Estimate = lazy(() => import('apps/Estimate'));
const MarkAsPaid = lazy(() => import('embedded/MarkAsPaid'));
const PaymentActivity = lazy(() => import('embedded/PaymentActivity'));
const RefundPayment = lazy(() => import('embedded/RefundPayment'));
const Invoice = lazy(() => import('apps/Invoice'));
const Offer = lazy(() => import('apps/Offer'));
const Onboarding = lazy(() => import('apps/Onboarding'));
const Receipt = lazy(() => import('apps/Receipt'));
const UniversalPayments = lazy(() => import('embedded/UniversalPayments'));
const Portal = lazy(() => import('apps/Portal'));
const ChargebackGuaranteeTerms = lazy(() =>
  import('apps/ChargebackGuaranteeTerms')
);
const DisputeEvidence = lazy(() => import('apps/DisputeEvidence'));
const MaintenanceCheck = React.lazy(() => import('shared/MaintenanceCheck'));
/*
 * Top level router that decides which sub-application to load.
 * This will load first on every request, then load the code-split app bundle based on route.
 *
 * NOTE: Because this gets loaded first on EVERY request, make sure to only include code here
 * that EVERY application needs.
 */
function Router() {
  return (
    <ErrorBoundary fallback={<h1>Something went wrong.</h1>}>
      <GlobalStyles />
      <GlobalSmartSnackbar />
      <Suspense fallback="">
        <BrowserRouter>
          <ApolloProvider client={clientPublic}>
            <AmplitudeProvider skipCurrentUser>
              <MaintenanceCheck>
                <Switch>
                  <Route path="/current_fees" component={CurrentFees}></Route>
                  <Route
                    path="/chbg_tos"
                    component={ChargebackGuaranteeTerms}
                  ></Route>
                  <Route path="/reports" component={PaymentActivity}></Route>
                  <Route path="/estimates/:estimateUid" component={Estimate} />
                  <Route
                    path="/invoices/:invoiceUid/refunds/new"
                    component={RefundPayment}
                  ></Route>
                  <Route
                    path="/invoices/:invoiceUid/cancel_payment_request"
                    component={CancelPaymentRequest}
                  ></Route>
                  <Route
                    path="/invoices/:invoiceUid/mark_as_paid"
                    component={MarkAsPaid}
                  ></Route>
                  <Route
                    path="/invoices/:invoiceUid"
                    component={Invoice}
                  ></Route>
                  <Route path="/offers/:offerUid" component={Offer}></Route>
                  <Route
                    path="/accounts/:accountUid/edit"
                    component={Onboarding}
                  ></Route>
                  <Route
                    path="/universal_payments"
                    component={UniversalPayments}
                  ></Route>
                  <Route
                    path="/payments/:paymentUid/receipt"
                    component={Receipt}
                  ></Route>
                  <Route
                    path="/payments/dispute-evidence/:disputeUid"
                    component={DisputeEvidence}
                  ></Route>
                  <Route
                    path="/composer_app_invoice_form"
                    component={UniversalPayments}
                  ></Route>
                  <Route
                    path="/ach-redirect"
                    component={PaymentAchRedirectLanding}
                  ></Route>
                  <Route path="/portal" component={Portal}></Route>
                </Switch>
              </MaintenanceCheck>
            </AmplitudeProvider>
          </ApolloProvider>
        </BrowserRouter>
      </Suspense>
    </ErrorBoundary>
  );
}

export default Router;
