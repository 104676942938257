const PAYMENT_STATUS_TRACKING_EVENTS = {
  failedPaymentDetails: {
    opened: true,
  },
  disputes: {
    submitEvidenceClicked: true,
    evidenceSubmitted: true,
    disputeAccepted: true,
    emailLinkClicked: true,
  },
};

export default PAYMENT_STATUS_TRACKING_EVENTS;
